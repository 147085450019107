import React, { useState } from "react";
import { Form, Button, Container, Col, Alert, Card } from "react-bootstrap";
import axios from "axios";

import { Link } from "react-router-dom";

import Logo from "./assets/logo.svg";

type props = {
	token: string;
	setToken: (token: string) => void;
	setEmail: (email: string) => void;
};

const Login: React.FC<props> = ({ token, setToken, setEmail }) => {
	const [_email, _setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError]: [null | string, any] = useState(null);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			console.log(_email, password);
			const response = await axios.post("http://api.ahvw.com.au/login", {
				email: _email,
				password,
			});
			console.log(await response);
			if (response !== undefined) {
				const token = await response.data.token;
				setToken(token);
				setEmail(_email);
			}
		} catch (err) {
			setError("Invalid email or password");
		}
	};

	return (
		<Container
			className="d-flex justify-content-center align-items-center"
			style={{ minHeight: "100vh" }}>
			<Col
				xs={12}
				md={6}>
				<Card>
					<Card.Header className="flex flex-col items-center">
						<div className="logo-section ">
							<img
								src={Logo}
								alt="Logo"
							/>
						</div>
						<h2>Login</h2>
					</Card.Header>
					<Card.Body className="flex flex-col items-center">
						<Form
							onSubmit={handleSubmit}
							className="flex flex-col items-center">
							{error && <Alert variant="danger">{error}</Alert>}

							<Form.Group className="mb-3">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									placeholder="Enter email"
									value={_email}
									onChange={(e) => _setEmail(e.target.value)}
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Form.Group>

							<Button
								className="bg-purple-500 border-0 hover:bg-purple-600"
								type="submit">
								Login
							</Button>
							<div className="mt-3 text-center">
								<Link
									to="/request-password-reset"
									className="text-blue-600 hover:text-blue-800">
									Forgot Password?
								</Link>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Container>
	);
};

export default Login;
