import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { useEffect, useState } from "react";
import useLocalStorage from "./hooks/useLocalStorage";
import { VehiclesProvider } from "./contexts/VehiclesProvider";
import { ServicesProvider } from "./contexts/ServicesProvider";

import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PasswordResetRequest from "./PasswordResetRequest";
import PasswordReset from "./PasswordReset";

function App() {
    const [authToken, setAuthToken] = useLocalStorage("authToken", "");
    const [email, setEmail] = useLocalStorage("email", "");

    const handleLogout = () => {
        setEmail("");
        setAuthToken("");
    };

    const dashboard = (
        <VehiclesProvider>
            <ServicesProvider>
                <Dashboard
                    email={email}
                    authToken={authToken}
                    handleLogout={handleLogout}
                />
            </ServicesProvider>
        </VehiclesProvider>
    );

    const router = createBrowserRouter([
        {
            path: "/",
            element:
                authToken !== "" ? (
                    dashboard
                ) : (
                    <Login
                        token={authToken}
                        setToken={setAuthToken}
                        setEmail={setEmail}
                    />
                ),
        },
        {
            path: "/request-password-reset",
            element: <PasswordResetRequest />,
        },
        {
            path: "/reset-password",
            element: <PasswordReset />,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
