import React, { useState } from "react";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import Services from "./Services";
import Vehicles from "./Vehicles";

type Sidebar_props = {
    email: string;
    activeKey: string;
    setActiveKey: (key: string) => void;
    handleLogout: () => void;
};

const SERVICES_KEY = "services";
const VEHICLES_KEY = "vehicles";

const Sidebar: React.FC<Sidebar_props> = ({
    email,
    activeKey,
    setActiveKey,
    handleLogout,
}) => {
    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            setActiveKey(eventKey);
        }
    };

    return (
        <div style={{ width: "250px" }} className="d-flex flex flex-column">
            <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                <Nav variant="tabs" className="justify-content-center">
                    <Nav.Item>
                        <Nav.Link eventKey={SERVICES_KEY}>Services</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={VEHICLES_KEY}>Vehicles</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="border border-top-0 overflow-auto flex-grow-1">
                    <Tab.Pane eventKey={SERVICES_KEY}>
                        <Services />
                    </Tab.Pane>
                    <Tab.Pane eventKey={VEHICLES_KEY}>
                        <Vehicles />
                    </Tab.Pane>
                </Tab.Content>
                <div className="p-2 border-top-0 border small">
                    Your Email: <span className="text-muted">{email}</span>
                </div>
                <Button
                    className="rounded-0 bg-rose-500 hover:bg-rose-600 border-0"
                    onClick={handleLogout}
                >
                    Log Out
                </Button>
            </Tab.Container>
        </div>
    );
};

export default Sidebar;
