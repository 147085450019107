import { X } from "lucide-react";
import React, { useState, useEffect } from "react";
import { FormControl } from "react-bootstrap";

const SearchBar = ({
	onSearch,
}: {
	onSearch: (searchTerm: string) => void;
}) => {
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			onSearch(searchTerm);
		}, 300); // Delay for 300ms

		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm, onSearch]);

	const clearSearch = () => {
		setSearchTerm("");
		onSearch("");
	};

	return (
		<div className="flex justify-center my-4">
			<div className="flex border border-solid border-gray-300 rounded items-center max-w-xs w-full">
				<FormControl
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					placeholder="Search Vehicle"
					className="flex-grow px-4 py-2 focus:outline-none"
				/>
				{searchTerm && (
					<button
						onClick={clearSearch}
						className="ml-2 text-gray-500 hover:text-gray-700">
						<X className="w-6 h-6 text-rose-500" />
					</button>
				)}
			</div>
		</div>
	);
};

export default SearchBar;
