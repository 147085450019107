import React, { useState } from "react";
import { Form, Button, Container, Col, Alert, Card } from "react-bootstrap";
import axios from "axios";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import Logo from "./assets/logo.svg";

const PasswordReset = () => {
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const location = useLocation();
    const { token } = queryString.parse(location.search);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "http://api.ahvw.com.au/reset-password",
                {
                    token,
                    new_password: newPassword,
                }
            );
            setMessage(
                "Password successfully reset. You can now log in with your new password."
            );
        } catch (err) {
            setError("Failed to reset password. Please try again.");
        }
    };

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
        >
            <Col xs={12} md={6}>
                <Card>
                    <Card.Header className="text-center">
                        <div className="logo-section">
                            <img src={Logo} alt="Logo" />
                        </div>
                        <h2>Reset Your Password</h2>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            {message && (
                                <Alert variant="success">{message}</Alert>
                            )}
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form.Group className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Reset Password
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Container>
    );
};

export default PasswordReset;
