///@ts-nocheck
import React, { useContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const VehiclesContext = React.createContext<{
    vehicles: string[];
    selectedVehicle: string;
    selectVehicleIndex: (index: number) => void;
    createVehicle: (vehicle: Vehicle) => void;
    clearVehicles: () => void;
} | null>(null);

export function useVehicles() {
    return useContext(VehiclesContext);
}

type props = {
    children: any;
};

export const VehiclesProvider: React.FC<props> = ({ children }) => {
    const [vehicles, setVehicles] = useLocalStorage("vehicles", []);
    const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);

    function createVehicle(vehicle: Vehicle) {
        // Check if the vehicle already exists in the array
        const vehicleExists = vehicles.some(
            (existingVehicle: any) =>
                existingVehicle.vin === vehicle.VIN &&
                existingVehicle.rego === vehicle.Rego
        );

        if (vehicleExists) {
            console.log("Repeat");
        } else {
            setVehicles((prevVehicles: Vehicle[]) => {
                return [...prevVehicles, vehicle];
            });
        }
    }

    const getTimestamp = (dateString: string) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 0 : date.getTime(); // Handle invalid dates
    };

    const parseDate = (dateString) => {
        if (!dateString) {
            dateString = "";
        }
        const parts = dateString.split("/"); // Split the string by '/'
        if (parts.length === 3) {
            // Construct a new date in a format JavaScript understands (MM/DD/YYYY)
            return new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
        }
        return new Date(NaN); // Invalid date string, return an invalid Date object
    };

    const getDaysDifference = (nextServiceDate) => {
        const today = new Date();
        const serviceDate = parseDate(nextServiceDate);
        if (isNaN(serviceDate.getTime())) {
            return -Infinity; // Place invalid or missing dates at the end
        }
        return (serviceDate - today) / (1000 * 3600 * 24); // Convert to days
    };

    // Sort vehicles by Next Service Date
    const sortedVehicles = [...vehicles].sort((a: Vehicle, b: Vehicle) => {
        if (a.status === "Inactive" && b.status !== "Inactive") {
            return 1; // a should come after b
        } else if (b.Status === "Inactive" && a.status !== "Inactive") {
            return -1; // a should come before b
        }

        // If both have the same status, compare by NextServiceDate
        const diffA = getDaysDifference(a.NextServiceDate);
        const diffB = getDaysDifference(b.NextServiceDate);
        return diffB - diffA; // Descending order
    });

    const formattedVehicles = sortedVehicles.map(
        (service: Service, index: number) => {
            const selected = index === selectedVehicleIndex;
            return { ...service, selected };
        }
    );

    function clearVehicles() {
        setVehicles([]);
    }

    const value = {
        vehicles: formattedVehicles,
        selectedVehicle: formattedVehicles[selectedVehicleIndex],
        selectVehicleIndex: setSelectedVehicleIndex,
        createVehicle,
        clearVehicles,
    };

    return (
        <VehiclesContext.Provider value={value}>
            {children}
        </VehiclesContext.Provider>
    );
};
