//@ts-nocheck
import React from "react";
import { Card, Table } from "react-bootstrap";
import { useServices } from "./contexts/ServicesProvider";

function OpenService() {
    const { selectedService } = useServices();

    const serviceStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    };

    const columnStyle = {
        paddingLeft: "5px",
        flex: "0 0 48%",
        marginBottom: "10px",
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className="flex-grow-1 overflow-auto">
                <Card className="mb-3">
                    <Card.Header>
                        <h2 className="text-xl font-semibold">
                            Service Details
                        </h2>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Service ID
                                        </span>
                                    </td>
                                    <td>{selectedService.Service_ID}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            VIN Number
                                        </span>
                                    </td>
                                    <td>{selectedService.VIN_Number}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            SH_WS_VehRefNo
                                        </span>
                                    </td>
                                    <td>{selectedService.SH_WS_VehRefNo}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            SH_VEH_REGO
                                        </span>
                                    </td>
                                    <td>{selectedService.SH_VEH_REGO}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            SH_Company
                                        </span>
                                    </td>
                                    <td>{selectedService.SH_Company}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Date In
                                        </span>
                                    </td>
                                    <td>{selectedService.Date_In}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Date Out
                                        </span>
                                    </td>
                                    <td>{selectedService.Date_Out}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Service Status
                                        </span>
                                    </td>
                                    <td>{selectedService.Service_Status}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Service Date
                                        </span>
                                    </td>
                                    <td>{selectedService.ServiceDate}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Service KM
                                        </span>
                                    </td>
                                    <td>{selectedService.ServiceKM}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Service Type
                                        </span>
                                    </td>
                                    <td>{selectedService.ServiceType}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            SH_Service_Schedule
                                        </span>
                                    </td>
                                    <td>
                                        {selectedService.SH_Service_Schedule}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Next Service Date
                                        </span>
                                    </td>
                                    <td>{selectedService.NextServiceDate}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Next Service Type
                                        </span>
                                    </td>
                                    <td>{selectedService.NextServiceType}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Next Service KM
                                        </span>
                                    </td>
                                    <td>{selectedService.NextServiceKM}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="font-semibold">
                                            Notes
                                        </span>
                                    </td>
                                    <td>{selectedService.Notes}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default OpenService;
