import React from "react";
import { ListGroup } from "react-bootstrap";
import { useServices } from "./contexts/ServicesProvider";

function Services() {
    const { services, selectServiceIndex } = useServices()!;

    return (
        <ListGroup variant="flush">
            {/*@ts-ignore*/}
            {[...services].reverse().map((service: Service, index: number) => (
                <ListGroup.Item
                    key={index}
                    action
                    active={service.selected}
                    onClick={() =>
                        selectServiceIndex(services.length - 1 - index)
                    }
                >
                    {service.SH_VEH_REGO} - {service.ServiceType}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default Services;
