import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useVehicles } from "./contexts/VehiclesProvider";
import { useServices } from "./contexts/ServicesProvider";
import OpenService from "./OpenService";
import OpenVehicle from "./OpenVehicle";

import { Lock } from "lucide-react";

type props = {
    email: string;
    authToken: string;
    handleLogout: () => void;
};

const API_ADDRESS = "http://api.ahvw.com.au";

const SERVICES_KEY = "services";
const VEHICLES_KEY = "vehicles";

const Dashboard: React.FC<props> = ({ email, authToken, handleLogout }) => {
    const { createVehicle, clearVehicles, selectedVehicle } = useVehicles()!;
    const { createService, clearServices, selectedService } = useServices()!;

    const [activeKey, setActiveKey] = useState(SERVICES_KEY);

    useEffect(() => {
        const vehicles = async () => {
            if (!vehicles.length) {
                // Check if vehicles array is empty
                clearVehicles();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                };
                const response = await axios.get(
                    `${API_ADDRESS}/vehicles/company`,
                    config
                );
                const vehicles = response.data;
                vehicles.forEach((vehicle: Vehicle) => {
                    createVehicle(vehicle);
                });
            }
        };

        const services = async () => {
            if (!services.length) {
                // Check if services array is empty
                clearServices();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                };
                const response = await axios.get(
                    `${API_ADDRESS}/services/company`,
                    config
                );
                const services = response.data;
                services.forEach((service: Service) => {
                    createService(service);
                });
            }
        };

        vehicles();
        services();
    }, []);

    return (
        <div className="flex h-screen relative">
            <Sidebar
                email={email}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                handleLogout={handleLogout}
            />
            {activeKey === SERVICES_KEY && selectedService && <OpenService />}
            {activeKey === VEHICLES_KEY && selectedVehicle && <OpenVehicle />}
        </div>
    );
};

export default Dashboard;
