import React, { useState } from "react";
import { Form, Button, Container, Col, Alert, Card } from "react-bootstrap";
import axios from "axios";

import Logo from "./assets/logo.svg";

const PasswordResetRequest = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "http://api.ahvw.com.au/request-password-reset",
                {
                    email,
                }
            );
            setMessage(response.data.message);
        } catch (err) {
            setError("Failed to send reset email. Please try again.");
        }
    };

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
        >
            <Col xs={12} md={6}>
                <Card>
                    <Card.Header className="text-center">
                        <div className="logo-section">
                            <img src={Logo} alt="Logo" />
                        </div>
                        <h2>Reset Password</h2>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            {message && (
                                <Alert variant="success">{message}</Alert>
                            )}
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Send Reset Email
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Container>
    );
};

export default PasswordResetRequest;
