//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { useVehicles } from "./contexts/VehiclesProvider";
import SearchBar from "./SearchBar";

function Vehicles() {
    const { vehicles, selectVehicleIndex } = useVehicles()!;
    const [activeVehicles, setActiveVehicles] = useState(0);
    const [overdueVehicles, setOverdueVehicles] = useState(0);
    const [upcomingVehicles, setUpcomingVehicles] = useState(0);

    const [filteredVehicles, setFilteredVehicles] = useState(vehicles);

    const handleSearch = (searchTerm: string) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const filtered = vehicles.filter(
            (vehicle: Vehicle) =>
                vehicle.VIN.toLowerCase().includes(lowercasedSearchTerm) ||
                vehicle.VH_Company.toLowerCase().includes(
                    lowercasedSearchTerm
                ) || // Assuming you have a companyName field
                vehicle.Rego.toLowerCase().includes(lowercasedSearchTerm)
        );
        setFilteredVehicles(filtered);
    };

    const handleSelect = (index: number) => {
        const rego = filteredVehicles[index].Rego;
        const originalIndex = vehicles.findIndex(
            (vehicle) => vehicle.Rego === rego
        );
        selectVehicleIndex(originalIndex);
    };

    const backgroundColour = (dateStr: string, status: string) => {
        if (dateStr) {
            const [day, month, year] = dateStr.split("/").map(Number);
            const date = new Date(year, month - 1, day);

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const oneWeekFromToday = new Date(today);
            oneWeekFromToday.setDate(today.getDate() + 7);

            if (status === "Inactive") {
                return "secondary";
            }

            if (date < today) {
                return "danger";
            } else if (date < oneWeekFromToday) {
                return "warning";
            } else {
                return "success";
            }
        } else {
            return "success";
        }
    };

    const text = (dateStr: string, status: string) => {
        if (dateStr) {
            const [day, month, year] = dateStr.split("/").map(Number);
            const date = new Date(year, month - 1, day);

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const oneWeekFromToday = new Date(today);
            oneWeekFromToday.setDate(today.getDate() + 7);

            if (status === "Inactive") {
                return "Inactive";
            }

            if (date < today) {
                return "Overdue";
            } else if (date < oneWeekFromToday) {
                return "Upcoming";
            } else {
                return "Okay";
            }
        } else {
            return "Okay";
        }
    };

    useEffect(() => {
        setActiveVehicles(
            filteredVehicles.filter((vehicle) => vehicle.Status === "Active")
                .length
        );

        setOverdueVehicles(
            filteredVehicles.filter(
                (vehicle) => text(vehicle.Next_Service_Date) === "Overdue"
            ).length
        );

        setUpcomingVehicles(
            filteredVehicles.filter(
                (vehicle) => text(vehicle.Next_Service_Date) === "Upcoming"
            ).length
        );
    }, [filteredVehicles]);

    return (
        <ListGroup variant="flush">
            <SearchBar onSearch={handleSearch} />
            <div className="flex flex-row items-center bg-[#cfcfcf] py-2">
                <div className="flex flex-col items-center space-y-1">
                    <span className="font-semibold text-xs mx-2">Active:</span>
                    <Badge bg="dark">{activeVehicles}</Badge>
                </div>
                <div className="flex flex-col items-center space-y-1">
                    <span className="font-semibold text-xs mx-2">Overdue:</span>
                    <Badge bg="danger">{overdueVehicles}</Badge>
                </div>
                <div className="flex flex-col items-center space-y-1">
                    <span className="font-semibold text-xs mx-2">
                        Upcoming:
                    </span>
                    <Badge bg="warning">{upcomingVehicles}</Badge>
                </div>
            </div>
            {filteredVehicles.map((vehicle: Vehicle, index: number) => (
                <ListGroup.Item
                    className="flex flex-row justify-start items-center"
                    key={index}
                    action
                    active={vehicle.selected}
                    onClick={() => handleSelect(index)}
                >
                    {vehicle.Rego} - {vehicle.VH_Company}
                    <Badge
                        pill
                        bg={backgroundColour(
                            vehicle.Next_Service_Date,
                            vehicle.Status
                        )}
                        className="mx-3"
                    >
                        {text(vehicle.Next_Service_Date, vehicle.Status)}
                    </Badge>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default Vehicles;
