import React, { useContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const ServicesContext = React.createContext<{
    services: string[];
    selectedService: string;
    selectServiceIndex: (index: number) => void;
    createService: (Service: Service) => void;
    clearServices: () => void;
} | null>(null);

export function useServices() {
    return useContext(ServicesContext);
}

type props = {
    children: any;
};

export const ServicesProvider: React.FC<props> = ({ children }) => {
    const [services, setServices] = useLocalStorage("services", []);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(
        services.length - 1
    );

    function createService(Service: Service) {
        setServices((prevServices: Service[]) => {
            return [...prevServices, Service];
        });
    }

    function clearServices() {
        setServices([]);
    }

    const formattedServices = services.map(
        (service: Service, index: number) => {
            const selected = index === selectedServiceIndex;
            return { ...service, selected };
        }
    );

    const value = {
        services: formattedServices,
        selectedService: formattedServices[selectedServiceIndex],
        selectServiceIndex: setSelectedServiceIndex,
        createService,
        clearServices,
    };

    return (
        <ServicesContext.Provider value={value}>
            {children}
        </ServicesContext.Provider>
    );
};
