//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useVehicles } from "./contexts/VehiclesProvider";
import { useServices } from "./contexts/ServicesProvider";
import {
    Accordion,
    Card,
    ListGroup,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import axios from "axios";

function OpenVehicle() {
    const { selectedVehicle } = useVehicles();
    const { services } = useServices();
    const [nhvrData, setNHVRData] = useState([]);

    const data = nhvrData[0];

    const selectedVehicleServices = services.filter(
        (service) => service.VIN_Number === selectedVehicle.VIN
    );

    const getNHVRRecords = async () => {
        const url = `https://api-public.nhvr.gov.au/vehicle/vehicleRegistrations/search?qs=${selectedVehicle.Rego}&coordinate={"longitude": 123.0998233, "latitude": 89.9232332}`;
        const headers = {
            "Ocp-Apim-Subscription-Key": "564b8a77d7764ccdbd5231f6346c897e",
        };

        try {
            const response = await axios.get(url, { headers });
            console.log(response.data);
            setNHVRData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getNHVRRecords();
    }, [selectedVehicle.Rego]);

    const backgroundColour = (dateStr: string) => {
        const [day, month, year] = dateStr.split("/").map(Number);
        const date = new Date(year, month - 1, day);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const oneWeekFromToday = new Date(today);
        oneWeekFromToday.setDate(today.getDate() + 7);

        if (date < today) {
            return "rose";
        } else if (date < oneWeekFromToday) {
            return "amber";
        } else {
            return "emerald";
        }
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className="flex-grow-1 overflow-auto">
                <Container fluid>
                    <Row className="mb-4">
                        <Col>
                            <h2 className="text-2xl font-semibold">
                                Service Details
                            </h2>
                            <Card className="mb-4">
                                <Card.Header className="text-xl font-semibold">
                                    Important Information
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup.Item>
                                        <strong>VIN:</strong>{" "}
                                        {selectedVehicle.VIN}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Rego: </strong>
                                        {selectedVehicle.Rego}
                                    </ListGroup.Item>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-2xl font-semibold">
                                Services
                            </div>
                            <Accordion>
                                {[...selectedVehicleServices]
                                    .reverse()
                                    .map((service: Service, index) => (
                                        <Accordion.Item eventKey={`${index}`}>
                                            <Accordion.Header>
                                                <div>
                                                    <span className="font-semibold">
                                                        Current Service:{" "}
                                                    </span>
                                                    {service.ServiceType} -
                                                    {service.ServiceKM} -
                                                    {service.ServiceDate} - 
                                                    {service.SH_VEH_REGO}
                                                </div>
                                                <span className="w-2" />
                                                <div>
                                                    <span className="font-semibold">
                                                        Next Service:{" "}
                                                    </span>
                                                    {service.NextServiceType} -
                                                    {service.NextServiceKM} -
                                                    <span
                                                        className={
                                                            service.NextServiceType !==
                                                                "Repair" &&
                                                            service.NextServiceType !==
                                                                "Other"
                                                                ? "bg-yellow-200"
                                                                : ""
                                                        }
                                                    >
                                                        {
                                                            service.NextServiceDate
                                                        }
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Service ID:
                                                            </strong>{" "}
                                                            {service.Service_ID}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                VIN Number:
                                                            </strong>{" "}
                                                            {service.VIN_Number}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                SH_WS_VehRefNo:
                                                            </strong>{" "}
                                                            {
                                                                service.SH_WS_VehRefNo
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                SH_VEH_REGO:
                                                            </strong>{" "}
                                                            {
                                                                service.SH_VEH_REGO
                                                            }
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                SH_Company:
                                                            </strong>{" "}
                                                            {service.SH_Company}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Date In:
                                                            </strong>{" "}
                                                            {service.Date_In}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Date Out:
                                                            </strong>{" "}
                                                            {service.Date_Out}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Service Status:
                                                            </strong>{" "}
                                                            {
                                                                service.Service_Status
                                                            }
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Service Date:
                                                            </strong>{" "}
                                                            {
                                                                service.ServiceDate
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Service KM:
                                                            </strong>{" "}
                                                            {service.ServiceKM}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Service Type:
                                                            </strong>{" "}
                                                            {
                                                                service.ServiceType
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                SH_Service_Schedule:
                                                            </strong>{" "}
                                                            {
                                                                service.SH_Service_Schedule
                                                            }
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Next Service
                                                                Date:
                                                            </strong>{" "}
                                                            {
                                                                service.NextServiceDate
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Next Service
                                                                Type:
                                                            </strong>{" "}
                                                            {
                                                                service.NextServiceType
                                                            }
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Next Service KM:
                                                            </strong>{" "}
                                                            {
                                                                service.NextServiceKM
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <strong>
                                                                Notes:
                                                            </strong>{" "}
                                                            {service.Notes}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </Col>
                    </Row>
                    <div className="m-6" />
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className="text-xl font-semibold">
                                    Additional Information
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup.Item>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Fleet Number:</strong>{" "}
                                                {selectedVehicle.Fleet_Number}
                                            </div>
                                            <div className="col-md-6">
                                                <strong>Make:</strong>{" "}
                                                {selectedVehicle.Make}
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Type:</strong>{" "}
                                                {selectedVehicle.Type}
                                            </div>
                                            <div className="col-md-6">
                                                <strong>Customer</strong>{" "}
                                                {selectedVehicle.VH_Company}
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Year:</strong>{" "}
                                                {selectedVehicle.Year}
                                            </div>
                                        </div>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <div className="row">
                                            <div
                                                className={`col-md-6 bg-${backgroundColour(
                                                    selectedVehicleServices[
                                                        selectedVehicleServices.length -
                                                            1
                                                    ].NextServiceDate
                                                )}-500`}
                                            >
                                                <strong>
                                                    Next Service Date:
                                                </strong>{" "}
                                                {
                                                    selectedVehicleServices[
                                                        selectedVehicleServices.length -
                                                            1
                                                    ].NextServiceDate
                                                }
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="m-6" />
                    <Row>
                        <Col>
                            {data ? (
                                <Card style={{ width: "36rem" }}>
                                    <Card.Header>NHVR Records</Card.Header>
                                    <Row>
                                        <Col>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Vehicle ID:
                                                    </span>{" "}
                                                    {data.vehicleId}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Make:
                                                    </span>{" "}
                                                    {data.vehicleMake}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Model:
                                                    </span>{" "}
                                                    {data.vehicleModel}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Year Manufactured:
                                                    </span>{" "}
                                                    {
                                                        data.vehicleYearManufactured
                                                    }
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Chassis Number:
                                                    </span>{" "}
                                                    {data.vehicleChassisNumber}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Body Type:
                                                    </span>{" "}
                                                    {data.vehicleBodyType}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Primary Colour:
                                                    </span>{" "}
                                                    {data.vehiclePrimaryColour}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Tare Mass:
                                                    </span>{" "}
                                                    {data.vehicleTareMass} tons
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        GVM:
                                                    </span>{" "}
                                                    {data.vehicleGvm || "N/A"}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                        <Col>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        GCM:
                                                    </span>{" "}
                                                    {data.vehicleGcm} tons
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Registration
                                                        Jurisdiction:
                                                    </span>{" "}
                                                    {
                                                        data.registrationJurisdiction
                                                    }
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Plate Number:
                                                    </span>{" "}
                                                    {
                                                        data.registrationPlateNumber
                                                    }
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Registration End Date:
                                                    </span>{" "}
                                                    {data.registrationEndDate}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Charge Code:
                                                    </span>{" "}
                                                    {
                                                        data.registrationChargeCode
                                                    }
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Concession Type:
                                                    </span>{" "}
                                                    {data.registrationConcessionType ||
                                                        "N/A"}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Status:
                                                    </span>{" "}
                                                    {data.registrationStatus}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="font-semibold">
                                                        Last Updated:
                                                    </span>{" "}
                                                    {new Date(
                                                        data.registrationLastUpdated
                                                    ).toLocaleString()}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ) : (
                                <div>No NHVR Records Found</div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default OpenVehicle;
